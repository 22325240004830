/*------------------------------------*\
    # component.contract
\*------------------------------------*/

.contract {
    width: 100%;
}

.contract__title {
    margin-top: 40px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-dark;
    text-transform: none;
}

.contract__container {
    width: 100%;
    height: 504px;
    padding: 10px;
    margin-bottom: 60px;
    border: solid 1px $color-text-dark;
    overflow-y: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.contract__button {
    padding: 24px 88px;
    background: transparent;
    color: $brand-primary;
    border: 1px solid $brand-tertiary;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        background: $brand-primary;
        color: $color-bg;
    }
}

.contract__message {
    display: flex;
    margin-top: calc($unit * 10);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
}
