/*------------------------------------*\
    # component.title
\*------------------------------------*/

.title--withUtil {
    align-items: baseline;
}

/* @media (--screen-from-xxxlrg) {
    .title--withUtil {
        position: relative;
    }

    .title__util {
        position: absolute;
        left: calc($unit * -4);
        transform: translateX(-100%);
    }
} */
