/*------------------------------------*\
    # component.search
\*------------------------------------*/

.search__input--text,
.search__input--button {
    width: 100%;
}

.search__input--text {
    margin-bottom: calc($unit * 5);
}

.search__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 42px;

    @media (--screen-to-med) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.search__navigation__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: none;
    background: transparent;
    text-transform: uppercase;
    color: $color-text-dark;
    cursor: pointer;
    transition: color 0.2s ease-out;

    &:hover {
        color: $color-text;
    }

    &--active {
        color: $brand-primary;
    }
}

.search__navigation__buttons {
    display: flex;
    margin-left: -16px;

    @media (--screen-to-med) {
        margin-bottom: calc($unit * 3);
    }

    @media (--screen-to-sml) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.search__navigation__results {
    display: flex;

    @media (--screen-to-sml) {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
    }
}

.search__results {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 68px;
}

@media (--screen-from-sml) {
    .search {
        lost-flex-container: row;
    }

    .search__input--text {
        @lost gutter 0;
        lost-column: 9/12 flex;
        margin-bottom: 0;

        .input--text {
            border-right-width: 0;
        }
    }

    .search__input--button {
        lost-column: 3/12 flex;
    }
}

@media (--screen-from-lrg) {
    .search__input--text {
        lost-column: 10/12 flex;
    }

    .search__input--button {
        lost-column: 2/12 flex;
    }
}
