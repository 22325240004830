/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;

    display: inline-block;
    vertical-align: middle;
    width: 100%;

    /* Prefixed -webkit to remove border radius on iOS */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;
    background: transparent;

    text-decoration: none;
    font-family: $ff-sans;
    transition: 0.3s ease;
}

/* Input sizes */
.input--sml {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 6);
    line-height: calc($unit * 6);
}

.input--med {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 11);
    line-height: calc($unit * 11);
}

.input--lrg {
    @mixin type-scale $type-medium, 4;
    height: calc($unit * 18);
    line-height: calc($unit * 8);
    padding: 0 calc($unit * 6);
}

/* Input types */
.input--text {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0);
    color: $color-text;
    text-overflow: ellipsis;

    &::placeholder {
        transition: color 0.3s ease;
        color: color($color-text a(0.5));
    }

    &:focus,
    &:active {
        outline: 0;
        background-color: rgba(255, 255, 255, 0.1);

        &::placeholder {
            color: $color-text;
        }
    }
}

.input--number {
    padding-right: 0;
}

.input--select {
    display: inline-block;
    width: auto;
    background: transparent;
    @mixin type-scale $type-small, 4;
    letter-spacing: 1px;
    opacity: 1;
    color: $color-text;
}

.input--select--multiple {
    min-height: 160px;
}

.input--textarea {
    min-height: 160px;
    resize: vertical;
    border: 1px solid color(white blackness(+30%));
    padding: 0 calc($unit * 2);
    white-space: pre-wrap;
}

.input--radio {
    & + label {
        &:before {
            border-radius: 50%;
        }
    }
}

.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;

    & + label {
        padding-left: calc($unit * 2);

        &:before {
            content: '';
            display: block;
            float: left;
            width: calc($unit * 2.5);
            height: calc($unit * 2.5);
            margin-top: calc($unit / 2);

            transition: all 0.2s ease-in-out;

            border: 1px solid color(white blackness(+30%));
        }
    }

    &:focus {
        & + label {
            &:before {
                box-shadow: 0 0 5px $color-primary;
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                background: $color-primary;
                border-color: transparent;
            }
        }
    }
}
