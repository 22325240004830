/*------------------------------------*\
    # component.verification
\*------------------------------------*/

.verification__text {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-dark;
    text-transform: none;
}

.verification__textare {
    width: 100%;
    height: 504px;
    padding: 10px;
    background: transparent;
    border: solid 1px $color-text-dark;
    color: $color-text;
    overflow-y: auto;
}

.verification__input__wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 536px;
    margin-bottom: 40px;

    @media (--screen-to-sml) {
        flex-direction: column;
    }

    &--special {
        position: relative;

        @media (--screen-to-sml) {
            flex-direction: column;
        }
    }
}

.verification__textare__wrapper {
    position: relative;
    margin-bottom: 40px;
}

.verification__input {
    width: 100%;
    max-width: 356px;
    padding: 10px;
    height: calc($unit * 10);
    padding: 10px;

    &--special {
        width: 186px;
    }
}

.verification__label {
    margin-right: 20px;

    @media (--screen-to-sml) {
        margin-bottom: 12px;
    }
}

.verification__select {
    width: 100%;
    max-width: 356px;
    height: calc($unit * 10);
    padding: 10px;
    background: transparent;
    color: $color-text;

    &--special {
        width: 150px;
        margin-right: 20px;

        @media (--screen-to-sml) {
            margin-bottom: 12px;
        }
    }
}

.verification__button {
    padding: 24px 88px;
    background: transparent;
    color: $brand-primary;
    border: 1px solid $brand-tertiary;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        background: $brand-primary;
        color: $color-bg;
    }
}

.verification__error {
    position: absolute;
    right: 0;
    bottom: -24px;
    color: $color-warning;
}
