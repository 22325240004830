/*------------------------------------*\
    # component.logo
\*------------------------------------*/

.logo {
    transition: filter 0.3s ease;

    &--primary {
        &:hover,
        &:active {
            filter: saturate(1.75);
        }
    }
}
