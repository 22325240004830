/*------------------------------------*\
    # component.table
\*------------------------------------*/

@define-mixin table-label {
    display: inline-block;
    content: attr(data-label) ':';
    flex-wrap: wrap;
    margin-right: calc($unit * 2);
    @mixin type-scale $type-tiny, 4;
    opacity: 0.5;
    white-space: nowrap;
    flex-basis: 90px;
    flex-shrink: 0;
    margin: 0;
}

@define-mixin responsive-table {
    &,
    td,
    th,
    tbody {
        display: block;
    }

    thead {
        display: none;
    }

    tr {
        display: flex;
        flex-wrap: wrap;
        padding: calc($unit * 4) 0;
    }

    th,
    td {
        flex-basis: 100%;
        padding: 0 0 calc($unit * 3) 0;

        &:not(:last-child) {
            padding-right: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    [data-label] {
        display: flex;
        align-items: baseline;

        &:before {
            @mixin table-label;
        }
    }

    .table__label {
        @mixin table-label;
    }
}

.table {
    overflow-x: auto;
    overflow-y: hidden;
    word-break: break-word;
    width: 100%;
    border-collapse: collapse;

    &__label {
        display: none;
    }

    &__sml {
        margin-bottom: 0;
    }

    &--med {
        th,
        td {
            padding-top: calc($unit * 5);
            padding-bottom: calc($unit * 5);
        }
    }

    &--lrg {
        th,
        td {
            padding-top: calc($unit * 7);
            padding-bottom: calc($unit * 7);
        }
    }

    thead {
        @mixin type-scale $type-tiny, 4;
        border-bottom: 1px solid $color-border;
        opacity: 0.5;
    }

    th {
        font-weight: $type-weight-light;
    }

    th,
    td {
        vertical-align: middle;
        padding-top: calc($unit * 4);
        padding-bottom: calc($unit * 4);

        &:not(:last-child) {
            padding-right: calc($unit * 10);
        }
    }

    &--align-top {
        td,
        th {
            vertical-align: top;
        }
    }

    @media (--screen-to-med) {
        &-responsive--tablet {
            @mixin responsive-table;
        }
    }

    @media (--screen-to-xlrg) {
        &-responsive--desktop {
            @mixin responsive-table;
        }
    }

    &--paired {
        @media (--screen-to-lrg) {
            tr {
                display: block;
                padding: 0;
            }
        }

        th {
            padding-top: 0;
            padding-bottom: calc($unit * 3);
        }

        td {
            padding-top: 0;
            padding-bottom: calc($unit * 5);
        }

        th,
        td {
            width: 100%;

            display: block;

            &:first-child {
                width: auto;

                @media (--screen-from-xxlrg) {
                    min-width: 16em;
                }
            }

            &:last-child {
                width: 100%;
            }
        }
    }
}
