/*------------------------------------*\
    # component.pagination
\*------------------------------------*/

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 28px;

    @media (--screen-to-med) {
        flex-direction: column;
        align-items: center;
    }
}

.pagination__numbers {
    display: flex;
    align-items: center;

    @media (--screen-to-med) {
        margin-bottom: 12px;
    }
}

.pagination__results {
    @media (--screen-to-med) {
        margin-bottom: 12px;
    }
}

.pagination__number {
    color: $color-text-dark;
    background: transparent;
    padding: 0 6px;
    border: none;
    cursor: pointer;

    &:disabled {
        color: $color-text;
        cursor: auto;
    }
}

.pagination__button {
    position: relative;
    padding: calc($unit * 5);
    background: transparent;
    border: none;
    cursor: pointer;

    &::after {
        position: absolute;
        content: '';
        top: calc($unit * 4);
        width: calc($unit * 2);
        height: calc($unit * 2);
        border-left: 1px solid $color-text;
        border-bottom: 1px solid $color-text;
    }

    &:disabled {
        opacity: 0.4;
        cursor: auto;
    }

    &--prev {
        margin-right: calc($unit * 12);

        &::after {
            transform: rotate(45deg);
        }
    }

    &--next {
        margin-left: calc($unit * 12);

        &::after {
            transform: rotate(225deg);
        }
    }
}

.pagination__select {
    margin-left: calc($unit * 5);
    background: transparent;
    color: $color-text;
    border: none;
    cursor: pointer;
}

.pagination__dots {
    padding: 0 6px;
    color: $color-text-dark;
}
