/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer__wrapper {
    lost-flex-container: row;
    align-items: center;
}

.footer__col {
    lost-column: 4/12 flex;

    &:last-child {
        lost-column: 8/12 flex;
    }
}
