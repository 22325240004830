/*------------------------------------*\
    # component.fromto
\*------------------------------------*/

@media (--screen-to-med) {
    .fromTo--table {
        .fromTo__label {
            min-width: 90px;
            margin-right: 0;
        }
    }
}
