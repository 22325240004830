.chevron {
    display: flex;

    &:after {
        content: '';
        display: block;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        width: 6px;
        height: 6px;
        transform: translate3d(-1px, -1px, 0);
    }
}

.chevron--rounded {
    border-radius: 50%;
    background-color: $color-primary;
    color: $color-text;
    opacity: 1;
    padding: calc(2 * $unit);
    display: inline-block;
}

.chevron--sml {
    padding: calc(1.5 * $unit);
}

.chevron--lrg {
    &:after {
        width: 8px;
        height: 8px;
    }
}

.chevron--right {
    transform: rotate(-45deg) translate3d(1px, 1px, 0);
}

.chevron--left {
    transform: rotate(-225deg);
}

.chevron--down {
    transform: rotate(45deg) translate3d(1px, 1px, 0);
}

.chevron--up {
    transform: rotate(225deg);
}
