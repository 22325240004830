/*------------------------------------*\
    # component.transactions
\*------------------------------------*/

.transactions__wrapper {
    position: relative;
    padding: calc($unit * 10) calc($unit * 10) calc($unit * 5);
    margin-bottom: calc($unit * 15);
    border: 1px solid $color-border-dark;
    overflow: hidden;
    max-height: min-content;
    transition: max-height 0.4s ease-out;

    @media (--screen-to-lrg) {
        heigt: max-content;
        max-height: 1000px;
    }

    h4 {
        margin: 0 0 calc($unit * 5);
    }

    img {
        margin-bottom: 0;
    }

    &--active {
        max-height: 1500px;
    }

    &--expanded {
        max-height: max-content;
    }
}

.transaction__button {
    position: absolute;
    right: 0;
    top: calc($unit * 4);
    padding: calc($unit * 10);
    border: none;
    background: transparent;
    cursor: pointer;

    &::after {
        position: absolute;
        content: '';
        width: calc($unit * 4);
        height: calc($unit * 4);
        right: 30px;
        top: calc($unit * 7);
        border-top: 1px solid $color-text;
        border-left: 1px solid $color-text;
        transform: rotate(225deg);
        transition: transform 0.3s ease-out;
    }

    &--active {
        &::after {
            top: calc($unit * 9);
            transform: rotate(45deg);
        }
    }

    @media (--screen-from-sml) {
        right: calc($unit * 4);
    }

    @media (--screen-from-lrg) {
        right: calc($unit * 5);
        top: calc($unit * 17);
    }
}

.transaction__list {
    overflow-x: auto;
    overflow-y: hidden;
    word-break: break-word;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0;
}

.transaction__list__item {
    display: flex;
    flex-direction: column;
    margin-bottom: calc($unit * 5);
    margin-right: 18px;

    &--row {
        flex-direction: row;
    }

    &--special {
        margin-right: 10vw;
        margin-bottom: 0;
    }

    &--wrap {
        margin-right: calc($unit * 5);

        @media (--screen-from-sml) {
            margin-right: calc($unit * 20);
        }

        @media (--screen-from-lrg) {
            display: flex;
            flex-wrap: wrap;
            margin-right: 0;
        }
    }

    &--unexpanded-primary {
        display: flex;
        flex: 1 1 calc(26% - 18px);
    }

    &--unexpanded-secondary {
        display: flex;
        flex: 1 1 calc(16% - 18px);
    }
}
