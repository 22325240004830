/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.fb-100 {
    flex-basis: 100%;
}
