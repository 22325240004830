/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    touch-action: manipulation;
    user-select: none;

    /**
     * Remove border radius on iOS buttons
     */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    border: 0;
    background: transparent;

    transition: all 0.15s ease-out;

    /**
     * Disabled
     */

    &.is-disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.is-disabled {
        pointer-events: none;
    }
}

/**
 * Button styles
 */

.btn--primary {
    position: relative;
    border: 1px solid $color-primary;
    text-transform: uppercase;
    color: $color-primary;
    font-family: $ff-sans-compressed;
    letter-spacing: 0.1em;

    &:focus {
        outline: 0;
    }

    &:hover,
    &:active {
        outline: 0;
        background-color: $brand-primary;
        color: $color-bg;
    }

    &:disabled,
    &.is-disabled {
        &:hover,
        &:active {
            color: $color-primary;
        }
    }
}

.btn--ghost {
    background-color: transparent;
    text-transform: uppercase;
    color: $color-text;
    opacity: 0.5;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:hover,
    &:focus,
    &:active {
        outline: 0;
        opacity: 1;
        color: color($color-primary blackness(+20%));
    }

    &:disabled,
    &.is-disabled {
        &:hover,
        &:active {
            background-color: transparent;
            color: $color-primary;
        }
    }
}

.btn--ghost--active {
    &,
    &:hover,
    &:active {
        color: $color-primary;
        opacity: 1;
    }
}

.btn--ghost--padded {
    background-color: transparent;
    color: $color-primary;

    &:hover,
    &:active {
        color: color($color-primary blackness(+20%));
    }

    &:disabled,
    &.is-disabled {
        &:hover,
        &:active {
            background-color: transparent;
            color: $color-primary;
        }
    }
}

.btn--copy {
    background: transparent;
    cursor: pointer;
    border: none;
    transition: opacity 0.15s ease-out;

    &:hover,
    &:focus {
        opacity: 0.6;
    }
}

/**
 * Button sizes
 */

.btn--sml {
    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 12);
    padding: 0 calc($unit * 5);
}

.btn--med {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 12);
    padding: 0 calc($unit * 5);
}

.btn--lrg {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 18);
    padding: 0 calc($unit * 16);
}

/**
 * Spacer - a placeholder for text between
 * two or more buttons
 */

.btn-spacer {
    padding: 0 calc($unit);
    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: color(white blackness(+50%));
}
