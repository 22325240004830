/*------------------------------------*\
    # utils.align
\*------------------------------------*/

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-self-start {
    align-self: start;
}

.align-self-baseline {
    align-self: baseline;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: end;
}
