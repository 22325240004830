/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: calc($unit * 4);
}

/* Field label */
.field__lbl {
    display: block;
    margin-bottom: calc($unit);
    @mixin type-scale $type-small, 0.75;
    color: color($color-text a(0.85));
}

/* Field group holds input and buttons together */
.field__group {
    display: table;

    & > * {
        display: table-cell;
        vertical-align: top;
    }
}

/* Icon on the left or right of the input */
.field__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.field__icon--left {
    left: 0;
}

.field__icon--right {
    right: 0;
}
