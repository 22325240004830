/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1640px;
    margin: 0 auto;

    padding: 0 calc($unit * 4);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 12);
    }
}
