/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.bg-primary {
    background-color: $color-primary;
}

.bg-secondary {
    background-color: $color-secondary;
}
