/*------------------------------------*\
    # utils.color
\*------------------------------------*/

.color {
}

.color-success {
    color: $color-success;
}

.color-warning {
    color: $color-warning;
}

.color-note {
    color: $color-note;
}
