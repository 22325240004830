/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-inline {
    display: inline;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.d-none {
    display: none;
}
