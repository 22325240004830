/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
}

.container__content {
    margin: 0 auto;
    position: relative;
}
