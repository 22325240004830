/*------------------------------------*\
    # views.home
\*------------------------------------*/

.home {
    @media (--screen-from-xlrg) {
        &__wrapper {
            lost-flex-container: row;
            justify-content: space-between;
        }

        &__col--aside {
            lost-column: 4/12 flex;
        }

        &__col--main {
            lost-column: 7/12 flex;
        }
    }

    &__stats {
        lost-flex-container: row;
        justify-content: space-between;
        align-items: baseline;
    }

    &__summary {
        max-width: 540px;
    }
}
