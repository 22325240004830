/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    text-transform: uppercase;
    font-weight: $type-weight-regular;
    font-family: $ff-sans;
    text-rendering: optimizeLegibility;
}

h1 {
    @mixin type-scale $type-large, 22;
}

h2 {
    @mixin type-scale $type-medium, 19;
}

h3 {
    @mixin type-scale $type-base, 6;
    font-family: $ff-sans-compressed;
    letter-spacing: 4px;
    color: $brand-secondary;
}

/**
 * Paragraphs
 */

p {
    margin: 0;
}

/**
 * Lists (ol, ul, dd)
 */

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol {
    list-style-type: decimal-leading-zero;
}

/**
 * Anchors
 */

a {
    color: $color-primary;
    text-decoration: none;
    transition: color 0.15s ease-out;

    &:hover {
        color: color($color-primary blackness(+25%));
    }

    &:focus {
        color: color($color-primary blackness(+25%));
    }
}

/**
 * Typographic details
 */

hr {
    padding: calc($unit * 2) 0;
    border: 0;
    border-bottom: 1px solid color(white blackness(+15%));
}

em {
}

b,
strong {
    font-weight: $type-weight-bold;
}

/**
 * Tables
 */

table {
    th {
        text-align: left;
    }
}

/**
 * Preformatted
 */

pre {
    margin: 0;
    font-family: $ff-mono;
    width: 100%;
    max-width: 1280px;
    white-space: initial;
}
