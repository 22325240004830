/*------------------------------------*\
    # component.summary
\*------------------------------------*/

.summary__wrapper {
    lost-flex-container: row;
}

.summary__col {
    lost-column: 1/2 flex;
}
