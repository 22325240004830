/*------------------------------------*\
    # component.loaders
\*------------------------------------*/

.skeletonLoader {
    background-image: linear-gradient(
        90deg,
        $color-bg-alt 0px,
        color($color-bg-alt lightness(+5%)) 40px,
        $color-bg-alt 80px
    );
    min-width: 50px;
    max-width: 700px;
    opacity: 0.5;
    background-size: 200% 100%;
    animation: skeletonLoader 2.5s infinite ease;
}

.skeletonLoader--fullWidth {
    min-width: 100%;
    flex-basis: 100%;
}

.skeletonLoader--tny {
    padding-bottom: calc($unit * 3);
}

.skeletonLoader--sml {
    padding-bottom: calc($unit * 5);
}

.skeletonLoader--med {
    padding-bottom: calc($unit * 6);
}

.skeletonLoader--lrg {
    padding-bottom: calc($unit * 10);
}

.skeletonLoader--xlrg {
    padding-bottom: calc($unit * 24);
}

.skeletonLoader--xxlrg {
    padding-bottom: calc($unit * 60);
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $color-bg;
}

.loader__wrapper {
    background-color: $color-bg-alt;
    min-height: calc($unit / 2);
    max-width: 240px;
    flex: 1 1 100%;
}

.loader__progress {
    background-color: $color-primary;
    min-height: calc($unit / 2);
}

@keyframes skeletonLoader {
    0% {
        background-position: 100% 0;
    }
    70%,
    100% {
        background-position: -100% 0;
    }
}
