/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.opacity-100 {
    opacity: 1;
}

.opacity-90 {
    opacity: 0.9;
}

.opacity-80 {
    opacity: 0.8;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-30 {
    opacity: 0.3;
}

.opacity-20 {
    opacity: 0.2;
}
